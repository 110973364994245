import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Spinner} from 'react-bootstrap';
import Textarea from '../../components/textarea/textarea';
import Select from '../../components/select/select';
import { ChangeEvent, ChangeEventHandler, KeyboardEvent } from 'react';
import React from 'react'
import Input from '../../components/inputs/input';
import { optionSelect } from '../../components/select/input-select.interface';
import './form-inscription.scss'
import { FormEntitie } from '../../entities/form.entitie';
import { Career } from '../../entities/careers';
import DropDrownInput from '../../components/dropdown-input/dropdown-input';
import Success from '../success/success';
import OfferAcademicService from '../../services/offer-academic.service';
import LeadService from '../../services/lead.service';

class FormInscription extends React.Component<any, any> {


  constructor(props: any) {
    super(props);
    this.sendInformation = this.sendInformation.bind(this);
    this.state = {
      name: '',
      lastName: '',
      email: '',
      phone: '',
      cedula: '',
      description: '',
      idCareer: '',
      idCampus: '',
      newEmail: '',
      hasErrorRepeatEmail: false,
      hasErrorEmail: false,
      hasErrorCedula: false,
      hasErrorName: false,
      hasErrorLastName: false,
      hasErrorPhone: false,
      careers: [],
      titlePhoneDropdown: 'País',
      optionsCareers: [],
      optionsCampus: [],
      isLoading: false,
      hasErrorToSend: false,
      isSended: false
    }

  }

  componentDidMount() {
    OfferAcademicService.getAll().then((x: any) => {
      if (x.data && x.data.respuesta) {
        this.setState({
          careers: x.data.respuesta,
          optionsCareers: x.data.respuesta.map((x: { id_carrera: any; nombre_carrera: any; }) => { return { value: x.id_carrera, label: x.nombre_carrera } })
        });
      }
    });
  }


  changeOptionsCampus = (e: ChangeEvent<any>) => {
    this.setDataSelects(e);
    const id = e.target.value;
    const career: Career = this.state.careers.find((x: { id_carrera: string; }) => x.id_carrera === id);

    if (career) {
      const optionsCampus = career.sedes.map<optionSelect>(x => { return { value: x.id_sede, label: x.nombre_sede } })
    
      this.setState({
        idCampus : '0',
        optionsCampus: optionsCampus
      })
    }
  }


  saveData = (e: ChangeEvent<any>) => {
    this.setState({ [e.target.id]: e.target.value });
  }

  validateCedulaAndSaveData = (e: ChangeEvent<any>) => {
    var pattern = new RegExp(/^[0-9]*[.,]?$/);
    var testCedulaPattern = pattern.test(e.target.value);
    this.setState({ hasErrorCedula: !testCedulaPattern });

    if (testCedulaPattern) {
      this.saveData(e);
    }
  }

  validateEmailAndSaveData = (e: ChangeEvent<any>) => {

    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    var testEmailFormatValid = pattern.test(e.target.value);
    this.setState({ hasErrorEmail: !testEmailFormatValid });

    if (testEmailFormatValid) {
      this.saveData(e);
    }
  }


  setDataSelects = (e: ChangeEvent<any>) => {
    this.setState({ [e.target.id]: e.target.value });
  }

  checkErrorRepeatEmail = (e: ChangeEvent<any>) => {
    const isValid = this.state.email === e.target.value ;
    this.setState({ hasErrorRepeatEmail: !isValid });
    if (isValid) {
      this.saveData(e);
    }
  }

  

  checkErrorPhone = (e: any) => {
    var pattern = new RegExp(/^[0-9]*$/i);

    
    var invalidKey = e.code === "KeyE" || e.code === "Period" || e.code === "NumpadDecimal";

    const valueRaro = e.target.value == "" && this.state.phone !== "";
    
    var formatName = pattern.test(e.target.value);
    
    var valid = formatName && !invalidKey && !valueRaro;
    this.setState({ hasErrorPhone: !valid });

    if (valid) {
      this.saveData(e);
    }

  }

  checkErrorName = (e: ChangeEvent<any>) => {
    var pattern = new RegExp(/^[ñA-Za-z _]*[ñA-Za-z][ñA-Za-z _]*$/i);
    
    var formatName = pattern.test(e.target.value);
    this.setState({ hasErrorName: !formatName });

    if (formatName) {
      this.saveData(e);
    }

  }

  checkErrorLastName = (e: ChangeEvent<any>) => {
    var pattern = new RegExp(/^[ñA-Za-z _]*[ñA-Za-z][ñA-Za-z _]*$/i);
    var formatName = pattern.test(e.target.value);
    this.setState({ hasErrorLastName: !formatName });

    if (formatName) {
      this.saveData(e);
    }

  }

  onClick: React.MouseEventHandler<any> = (e) => {
    e.preventDefault();
    this.sendInformation()
  }

  sendInformation() {
    const { name,
      lastName,
      email,
      phone,
      idCareer,
      idCampus,
      cedula,
      description, } = this.state;
    const model = new FormEntitie(name,
      lastName,
      email,
      phone,
      idCareer,
      this.state.careers.find((x: any) => x.id_carrera === idCareer).nombre_carrera,
      idCampus,
      description,
      cedula
      )

      this.setState({isLoading: true});

      LeadService.create(model).then(x => {
        this.setState({ isSended : true, isLoading: false})
      }).catch(x => {
        this.setState({ isSended : false, isLoading: false, hasErrorToSend: true})
      });
  }


  render() {

    const { name,
      lastName,
      email,
      phone,
      newEmail,
      hasErrorRepeatEmail,
      hasErrorEmail,
      hasErrorCedula,
      hasErrorName,
      hasErrorLastName,
      hasErrorPhone,
      idCareer,
      idCampus } = this.state;
    const enabled = (name.length > 0 && !hasErrorName) && lastName.length > 0 && email.length > 0 && phone.length > 0  && newEmail.length > 0 
                     && idCareer.length > 0 && (idCampus.length > 0 && idCampus != "0") && !hasErrorRepeatEmail && !hasErrorRepeatEmail && !hasErrorEmail && !hasErrorLastName &&
                     !hasErrorPhone && !hasErrorCedula;

    const isLoading = this.state.isLoading ?  <div > <Spinner animation="border" role="status"></Spinner> </div>  : <Button type="submit" variant="uninorte" size="lg" disabled={!enabled} onClick={this.onClick}> Enviar!</Button>  ;

    window.scrollTo(0, 0);
    
    return (
      (this.state.isSended || this.state.hasErrorToSend) ?    
      <> <Success message="Informacion enviada con exito!"> </Success></> 
      :
      <>

        <form className="container mt-3">
          <div className="card mb-3">
            <div className="card-body">
              <div className="card-title-text">
                    <h5>¿Querés ser alumno? </h5>
                    Dejanos tus datos y nos pondremos en contacto contigo
                  </div>
              <br/>
              <div className="row">
                <div className="col-md-6 pb-3">
                  <Input required={true} name="Nombre" id="name" type="text" handleChange={this.saveData} handleKeyUp={this.checkErrorName} 
                  hasError={this.state.hasErrorName} textError="El nombre solo lleva letras."></Input>
                </div>
                <div className="col-md-6 pb-3">
                  <Input name="Apellido " required={true} id="lastName" type="text" handleChange={this.saveData} handleKeyUp={this.checkErrorLastName}
                  hasError={this.state.hasErrorLastName} textError="El apellido solo lleva letras." ></Input>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pb-3">
                  <Input name="Email  " required={true} id="email" type="email" handleKeyUp={this.validateEmailAndSaveData}
                    hasError={this.state.hasErrorEmail} textError="El email debe cumplir su formato"></Input>
                </div>
                <div className="col-md-6 pb-3">
                  <Input name="Repetí tu email " required={true} id="newEmail" type="email" handleKeyUp={this.checkErrorRepeatEmail}
                    handleOnBlur={this.checkErrorRepeatEmail}
                    hasError={this.state.hasErrorRepeatEmail} textError="El email debe coincidir"></Input>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pb-3">
                  <Input name="Cedula" id="cedula" type="text" hasError={this.state.hasErrorCedula}
                  handleChange={this.validateCedulaAndSaveData} textError="Ingresar cedula valida" ></Input>
                </div>
                <div className="col-md-6 pb-3">
                  <DropDrownInput  name="País" id="phone" 
                   options={[{value:"par", label:"Paraguay", valueInput: '595' },{value:"arg", label:"Argentina", valueInput: '54'}, {value:"bra", label:"Brasil",valueInput: '55'}]}
                   optionOut={{value:"otro", label:"Otro", valueInput: ''}}  handleKeyUp={this.checkErrorPhone} 
                   hasError={this.state.hasErrorPhone} textError="El telefono debe ser solo numeros">

                   </DropDrownInput>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 pb-3">
                    <Select id="idCareer" label="Carrera " required={true} options={this.state.optionsCareers}
                      handleChange={this.changeOptionsCampus}
                      defaultValue="Seleccioná tu carrera"> </Select>
                  </div>
                  <div className="col-md-6 pb-3">
                    <Select id="idCampus" label="Sede "  required={true} options={this.state.optionsCampus}
                      handleChange={this.setDataSelects}
                      defaultValue="Seleccioná tu sede" selected={this.state.idCampus}> </Select>
                  </div>
                </div>
                <Textarea name="Comentarios" id="description" handleChange={this.saveData}></Textarea>

               {isLoading}
              </div>
            </div>
        </form>
      </>   
    )
  }
}

export default FormInscription;


