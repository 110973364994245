import React from 'react';
import './input.scss'

interface InputProps {
  id: string,
  name: string,
  type: string,
  value?: string,
  required?: boolean,
  placeholder?: string,
  handleChange?: React.ChangeEventHandler<HTMLInputElement>,
  handleOnBlur?: React.FocusEventHandler<HTMLInputElement>,
  handleOnFocus?: React.FocusEventHandler<HTMLInputElement>,
  handleKeyPress?: React.KeyboardEventHandler<any>,
  handleKeyUp?: React.KeyboardEventHandler<any>,
  hasError?: boolean,
  textError?: string
}

class Input extends React.Component<InputProps, any> {

  constructor(props: InputProps) {
    super(props);
  }

  render() {
    const error = this.props.hasError ? <div className="kui-textfield-outlined-helper-line">
                                          <div className="kui-textfield-outlined-helper-text__error">
                                            {this.props.textError}
                                          </div>
                                        </div> : null;
    const text = this.props.required ? <span >{this.props.name} <p style={{fontWeight: 700, color: 'red'}}>  *</p></span> :  <span>{this.props.name}</span>;
    return (
      <div className="kui-form-container  ">
        <label className={this.props.hasError ? 'kui-textfield-outlined kui-textfield-outlined__error mb-0' : 'kui-textfield-outlined mb-0'} htmlFor="namee">
          <input name={this.props.name} id={this.props.id} type={this.props.type || "text"} placeholder={this.props.placeholder}
            value={this.props.value} required={this.props.required}
            onChange={this.props.handleChange} onBlur={this.props.handleOnBlur} onFocus={this.props.handleOnFocus} 
            onKeyUp={this.props.handleKeyUp}
            onKeyPress={this.props.handleKeyPress}/>
          {text}
        </label>
        {error}
      </div>
    )
  }
}

export default Input;