export class FormEntitie {
    public nombre: string = "";
    public apellido: string= "";
    public mail: string= "";
    public telefono: string= "";
    public id_carrera: string= "";
    public nombre_carrera: string= "";
    public id_sede: string= "";
    public observaciones: string= "";
    public nrodocumento: string= "";

    constructor(nombre: string,
    apellido: string,
    mail: string,
    telefono: string,
    id_carrera: string,
    nombre_carrera: string,
    id_sede: string,
    observaciones: string,
    nrodocumento: string) {
        this.nombre = nombre;
        this.apellido = apellido;
        this.mail = mail;
        this.telefono = telefono;
        this.id_carrera = id_carrera;
        this.nombre_carrera  = nombre_carrera;
        this.id_sede = id_sede;
        this.observaciones = observaciones;
        this.nrodocumento = nrodocumento;
    }

}