import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import './success.scss'

interface SuccessProps {
  message: string,
}

class Success extends React.Component<SuccessProps, any> {

  constructor(props: SuccessProps) {
    super(props);
  }


  render() {
   

    return (
      <div className="text-center color py-5">
                <div className="mb-1 color-icon">
                     <FontAwesomeIcon icon={faCheckCircle} size="7x"/>
                </div>
                <p className="text-center text-white kui-24 font-weight-medium mb-2">{ this.props.message }</p>     
      </div>
    )
  }
}

export default Success;