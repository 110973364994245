
import React from 'react';
import './footer.scss'
// get our fontawesome imports
import { faFacebookF, faTwitter, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Footer = () => {
    return (
        <footer>
          
            <div className="footer-container">
                <div className="footer-container-row ">
                    <div className="footer-col-3">
                        <img alt="" src="https://www.uninorte.edu.py/wp-content/uploads/2017/03/escudo-universidaddelnorte.png"></img>
                    </div>
                    <div className="footer-col-3">  
                            <ul>
                                    <li><a href="https://www.uninorte.edu.py/?page_id=9161">Facultades</a></li>
                                    <li> <a href="https://www.uninorte.edu.py/?page_id=8968">Carreras de Grado</a></li>
                                    <li> <a href="https://www.uninorte.edu.py/?page_id=9215">Sedes</a></li>
                                </ul>
                    </div>
                    <div className="footer-col-3">
                        <ul>
                            <li><a href="https://www.uninorte.edu.py/?page_id=9608">Extensión Cultural</a></li>
                            <li><a href="https://www.uninorte.edu.py/?page_id=10944">Extensión Universitaria</a></li>
                            <li><a href="https://www.uninorte.edu.py/?page_id=604">Historia de la Universidad</a></li>
                            <li><a href="https://www.uninorte.edu.py/?page_id=558">Contacto</a></li>
                        </ul>
                    </div>
                    <div className="footer-col-3">
                        <ul>
                            <li><a href="javascript:void(0);">Universidad del Norte</a></li>
                            <li><a href="javascript:void(0);"> Avda. España 676 casi Boquerón</a></li>
                            <li><a href="javascript:void(0);">Tel.: (595-21) 229-450</a></li>
                            <li><a href="javascript:void(0);">Email: info@uninorte.edu.py</a></li>
                            <li><a href="javascript:void(0);">Asunción – Paraguay</a></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-container-row">
                    <div className="footer-col-6"> 
                        <p className="text-copyright">© 2021 Todos los derechos reservados.</p>
                    </div>
                        <div className="footer-col-6 social">
                            <div className="social-icons">
                                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/uninorteparaguay/" ><FontAwesomeIcon icon={faFacebookF} /></a>
                                <a target="_blank"  rel="noreferrer" href="https://twitter.com/uninorte_py" > <FontAwesomeIcon icon={faTwitter} /></a>
                                <a target="_blank"  rel="noreferrer" href="https://www.instagram.com/uninorteparaguay/" ><FontAwesomeIcon icon={faInstagram} /></a>
                                <a target="_blank"  rel="noreferrer" href="https://www.youtube.com/user/audiovisualuninorte" ><FontAwesomeIcon icon={faYoutube} /></a>
                            </div>
                        </div>
                </div>
            </div>
        </footer>
    )
};

export default Footer;