import React from 'react';
import { inputSelect } from './input-select.interface';
import './select.scss'

  class Select extends React.Component<inputSelect, any> {

    constructor(props: inputSelect) {
      super(props);
      this.state = {
        value : '0'
      }
    }


  
    render() {
      const defaultValue = <option value="0" key="def" selected={true} disabled={false} hidden={true}> {this.props.defaultValue}</option>
     
      const selectOptions = this.props.options.map(({ value, label, selected, disabled, hidden }) => (
        <option value={value} key={value} selected={selected} disabled={disabled} hidden={hidden}> {label}</option>
      ));
      const optionsToSelect = [defaultValue, ... selectOptions];
      const text = this.props.required ? 
      <label className="select-label">{this.props.label} <span style={{fontWeight: 700, color: 'red'}}>  *</span> </label> :   <label className="select-label">{this.props.label} </label>;  
      //const options =  this.props.options && this.props.options.length > 0 ? selectOptions : null; 
      return (
        <div className="dgr-select-outlined" >
          <select id={this.props.id} className="select-text" onChange={this.props.handleChange} value={this.props.selected}>
          
            {optionsToSelect}
              </select>
           {text}
        </div>
      ) 
    }
  }

export default Select;