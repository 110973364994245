import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import Footer from './components/footer/footer';
import FormInscription from './pages/form-inscription/form-inscription';

function App() {

  return (
    <>
      <header className="header">
        <img src={process.env.PUBLIC_URL + '/uninorte_logo.png'} alt=""></img>
      </header>

      <div className="App">
        <div className="wrapper">
        <FormInscription></FormInscription>
        </div>
      </div>

      <Footer ></Footer>
    </>
  );
}

export default App;
