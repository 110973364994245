import React, { ChangeEvent, LegacyRef, MouseEventHandler } from 'react';
import './dropdown-input.scss';
interface DropDrownInputInterface {
  value: string;
  label: string;
  valueInput: string;
}

interface DropDrownInputProps {
  id: string,
  name: string,
  options:DropDrownInputInterface[],
  optionOut?: DropDrownInputInterface,
  value?: string,
  placeholder?: string,
  handleChange?: React.ChangeEventHandler<HTMLInputElement>,
  handleOnBlur?: React.FocusEventHandler<HTMLInputElement>,
  handleOnFocus?: React.FocusEventHandler<HTMLInputElement>
  handleKeyDown?: React.KeyboardEventHandler<any>,
  handleKeyUp?: React.KeyboardEventHandler<any>,
  hasError?: boolean,
  textError?: string
}

class DropDrownInput extends React.Component<DropDrownInputProps, any> {
  input: LegacyRef<HTMLInputElement>;

  constructor(props: DropDrownInputProps) {
    super(props);
    this.state = {
      show : false,
      title: this.props.name,
      valueInput:''
    }
    this.onClickOption = this.onClickOption.bind(this);
    this.input = React.createRef();
  }


  
  openDropDown = (e: ChangeEvent<any>) => {
    this.setState({show: !this.state.show})
  }

  onClickOption = (e: ChangeEvent<any>) => {
    const input : any = this.input;
    input.current.value = e.target.id ;
    this.setState({show: !this.state.show, title: e.target.text, valueInput: e.target.id })
    e.preventDefault();
  }


  render() {

    const selectOptions = this.props.options.map(({ value, label, valueInput }) => (
      
      <a className="dropdown-item pointer" id={valueInput} onClick={this.onClickOption}>{label}</a>
    ));

    const optionSeparated = this.props.optionOut ? <a className="dropdown-item" id={this.props.optionOut.valueInput}  onClick={this.onClickOption} >{this.props.optionOut.label}</a> : null;
    const error = this.props.hasError ? <div className="kui-textfield-outlined-helper-line">
                                          <div className="kui-textfield-outlined-helper-text__error">
                                            {this.props.textError}
                                          </div>
                                        </div> : null;  

    return (
<>

      <div className="d-flex mb-3">
      <div className="input-group-prepend input-group-p" >
          <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={this.openDropDown} > 
          {this.state.title}
          </button>
          <div  className={this.state.show ? "dropdown-menu show" : "dropdown-menu "}>
            {selectOptions}
            <div role="separator" className="dropdown-divider"></div>
            {optionSeparated}
          </div>
        </div>
        <div className="kui-form-conta  ">
        <label className={this.props.hasError ? 'kui-textfield-outlined kui-textfield-outlined__error mb-0' : 'kui-textfield-outlined mb-0'} htmlFor="namee">
        <input  type="number" id={this.props.id} className="form-control" aria-label="Text input with dropdown button" ref={this.input} onChange={this.props.handleChange} 
         onKeyDown={this.props.handleKeyDown} onBlur={this.props.handleOnBlur} onKeyUp={this.props.handleKeyUp}/>
          
          <span>Telefono  <span style={{ fontWeight: 700, color: 'red'}}>  *</span></span>
        </label>
        
        </div>
        
    {error}
    </div>
    
    </>
    )
  }
}

export default DropDrownInput;